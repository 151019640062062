import client from './client'

const signup = (email, password) => client.post('/signup', { email, password }, { 'Access-Control-Allow-Origin': '*' })

const login = (phone, password) => client.post('/loginAdmin', { phone, password }, { 'Access-Control-Allow-Origin': '*' })

const resetPass = (email, password) => client.post('/resetPass', { email, password }, { 'Access-Control-Allow-Origin': '*' })

export default {
    signup,
    login,
    resetPass
}
