import React, {useState, useRef, userContext, useEffect} from 'react';
import { View, FlatList, TouchableOpacity, Text, Image, StyleSheet, ActivityIndicator, Dimensions, Alert} from 'react-native';
import colors from '../config/colors'
import services from '../api/services'

export default function Cashflow(){
  return(
    <View sytle={styles.container}>
      <Text>
        Cashflow Page
      </Text>
    </View>
  )
}
const styles = StyleSheet.create({
  container:{
    backgroundColor:'red'
  }
})