var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { radarChartDefaults } from '../utils/constants';
export var useRadarChart = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t = props.circular, circular = _t === void 0 ? false : _t, _u = props.gridConfig, gridConfig = _u === void 0 ? {} : _u, _v = props.polygonConfig, polygonConfig = _v === void 0 ? {} : _v, _w = props.data, data = _w === void 0 ? [] : _w, dataSet = props.dataSet, _x = props.noOfSections, noOfSections = _x === void 0 ? radarChartDefaults.noOfSections : _x, _y = props.chartSize, chartSize = _y === void 0 ? radarChartDefaults.chartSize : _y, _z = props.labelConfig, labelConfig = _z === void 0 ? {} : _z, labelConfigArray = props.labelConfigArray, _0 = props.asterLinesConfig, asterLinesConfig = _0 === void 0 ? {} : _0, _1 = props.hideGrid, hideGrid = _1 === void 0 ? radarChartDefaults.hideGrid : _1, _2 = props.hideAsterLines, hideAsterLines = _2 === void 0 ? (_a = props.hideGrid) !== null && _a !== void 0 ? _a : radarChartDefaults.hideAsterLines : _2, _3 = props.dataLabelsConfig, dataLabelsConfig = _3 === void 0 ? {} : _3, _4 = props.labelsPositionOffset, labelsPositionOffset = _4 === void 0 ? radarChartDefaults.labelsPositionOffset : _4, _5 = props.dataLabelsPositionOffset, dataLabelsPositionOffset = _5 === void 0 ? radarChartDefaults.dataLabelsPositionOffset : _5;
    var labels = (_e = (_b = props.labels) !== null && _b !== void 0 ? _b : (_d = ((_c = dataSet === null || dataSet === void 0 ? void 0 : dataSet[0]) !== null && _c !== void 0 ? _c : data)) === null || _d === void 0 ? void 0 : _d.map(function (_, index) { return "Label".concat(index + 1); })) !== null && _e !== void 0 ? _e : [];
    var getMax = function (dataSet) {
        return dataSet.reduce(function (acc, set) {
            var max = Math.max.apply(Math, __spreadArray([], __read(set), false));
            return max > acc ? max : acc;
        }, 0);
    };
    var maxValue = (_f = props.maxValue) !== null && _f !== void 0 ? _f : (dataSet ? Math.max(getMax(dataSet !== null && dataSet !== void 0 ? dataSet : [])) : Math.max.apply(Math, __spreadArray([], __read((data !== null && data !== void 0 ? data : [])), false)));
    var dataLabels = (_g = props.dataLabels) !== null && _g !== void 0 ? _g : (polygonConfig.showDataValuesAsLabels
        ? data.map(function (d) { return d.toString(); })
        : null);
    var polarToCartesian = function (angle, value) {
        var radians = (Math.PI / 180) * angle;
        return {
            x: center + radius * (value / maxValue) * Math.cos(radians),
            y: center - radius * (value / maxValue) * Math.sin(radians)
        };
    };
    var center = chartSize / 2;
    var radius = center * 0.8;
    var _6 = gridConfig.stroke, gridStroke = _6 === void 0 ? radarChartDefaults.gridSection.stroke : _6, _7 = gridConfig.strokeWidth, gridStrokeWidth = _7 === void 0 ? radarChartDefaults.gridSection.strokeWidth : _7, _8 = gridConfig.strokeDashArray, gridStrokeDashArray = _8 === void 0 ? radarChartDefaults.gridSection
        .strokeDashArray : _8, _9 = gridConfig.fill, gridFill = _9 === void 0 ? radarChartDefaults.gridSection.fill : _9, _10 = gridConfig.gradientColor, gridGradientColor = _10 === void 0 ? radarChartDefaults.gridSection
        .gradientColor : _10, _11 = gridConfig.showGradient, gridShowGradient = _11 === void 0 ? radarChartDefaults.gridSection
        .showGradient : _11, _12 = gridConfig.opacity, gridOpacity = _12 === void 0 ? radarChartDefaults.gridSection.opacity : _12, _13 = gridConfig.gradientOpacity, gridGradientOpacity = _13 === void 0 ? radarChartDefaults.gridSection
        .gradientOpacity : _13;
    var gridSections = (_j = (_h = gridConfig.gridSections) === null || _h === void 0 ? void 0 : _h.map(function (i) { return (__assign(__assign({}, radarChartDefaults.gridSection), i)); })) !== null && _j !== void 0 ? _j : Array(noOfSections).fill({});
    var _14 = labelConfig.fontSize, fontSize = _14 === void 0 ? radarChartDefaults.labelConfig.fontSize : _14, _15 = labelConfig.stroke, stroke = _15 === void 0 ? radarChartDefaults.labelConfig.stroke : _15, _16 = labelConfig.textAnchor, textAnchor = _16 === void 0 ? radarChartDefaults.labelConfig.textAnchor : _16, _17 = labelConfig.alignmentBaseline, alignmentBaseline = _17 === void 0 ? radarChartDefaults.labelConfig.alignmentBaseline : _17, _18 = labelConfig.fontWeight, fontWeight = _18 === void 0 ? radarChartDefaults.labelConfig.fontWeight : _18, _19 = labelConfig.fontFamily, fontFamily = _19 === void 0 ? radarChartDefaults.labelConfig.fontFamily : _19;
    var _20 = dataLabelsConfig.fontSize, dataLabelsFontSize = _20 === void 0 ? fontSize : _20, // defaults to labelConfig (from above)
    _21 = dataLabelsConfig.stroke, // defaults to labelConfig (from above)
    dataLabelsColor = _21 === void 0 ? stroke : _21, // defaults to labelConfig (from above)
    _22 = dataLabelsConfig.textAnchor, // defaults to labelConfig (from above)
    dataLabelsTextAnchor = _22 === void 0 ? textAnchor : _22, // defaults to labelConfig (from above)
    _23 = dataLabelsConfig.alignmentBaseline, // defaults to labelConfig (from above)
    dataLabelsAlignmentBaseline = _23 === void 0 ? alignmentBaseline : _23, // defaults to labelConfig (from above)
    _24 = dataLabelsConfig.fontWeight, // defaults to labelConfig (from above)
    dataLabelsFontWeight = _24 === void 0 ? fontWeight : _24, // defaults to labelConfig (from above)
    _25 = dataLabelsConfig.fontFamily // defaults to labelConfig (from above)
    , // defaults to labelConfig (from above)
    dataLabelsFontFamily = _25 === void 0 ? fontFamily : _25 // defaults to labelConfig (from above)
    ;
    var _26 = polygonConfig.stroke, polygonStroke = _26 === void 0 ? radarChartDefaults.polygonConfig.stroke : _26, _27 = polygonConfig.strokeWidth, polygonStrokeWidth = _27 === void 0 ? radarChartDefaults.polygonConfig
        .strokeWidth : _27, _28 = polygonConfig.strokeDashArray, polygonStrokeDashArray = _28 === void 0 ? radarChartDefaults.polygonConfig
        .strokeDashArray : _28, _29 = polygonConfig.fill, polygonFill = _29 === void 0 ? radarChartDefaults.polygonConfig.fill : _29, _30 = polygonConfig.gradientColor, polygonGradientColor = _30 === void 0 ? radarChartDefaults.polygonConfig
        .gradientColor : _30, _31 = polygonConfig.showGradient, polygonShowGradient = _31 === void 0 ? radarChartDefaults.polygonConfig
        .showGradient : _31, _32 = polygonConfig.opacity, polygonOpacity = _32 === void 0 ? radarChartDefaults.polygonConfig.opacity : _32, _33 = polygonConfig.gradientOpacity, polygonGradientOpacity = _33 === void 0 ? polygonOpacity : _33, showDataValuesAsLabels = polygonConfig.showDataValuesAsLabels;
    var polygonConfigArray = (_l = (_k = props.polygonConfigArray) === null || _k === void 0 ? void 0 : _k.map(function (set) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return ({
            stroke: (_a = set.stroke) !== null && _a !== void 0 ? _a : polygonStroke,
            strokeWidth: (_b = set.strokeWidth) !== null && _b !== void 0 ? _b : polygonStrokeWidth,
            strokeDashArray: (_c = set.strokeDashArray) !== null && _c !== void 0 ? _c : polygonStrokeDashArray,
            fill: (_d = set.fill) !== null && _d !== void 0 ? _d : polygonFill,
            gradientColor: (_e = set.gradientColor) !== null && _e !== void 0 ? _e : polygonGradientColor,
            showGradient: (_f = set.showGradient) !== null && _f !== void 0 ? _f : polygonShowGradient,
            opacity: (_g = set.opacity) !== null && _g !== void 0 ? _g : polygonOpacity,
            gradientOpacity: (_h = set.gradientOpacity) !== null && _h !== void 0 ? _h : polygonGradientOpacity,
            showDataValuesAsLabels: (_j = set.showDataValuesAsLabels) !== null && _j !== void 0 ? _j : showDataValuesAsLabels
        });
    })) !== null && _l !== void 0 ? _l : (dataSet
        ? Array(dataSet.length).fill({
            stroke: polygonStroke,
            strokeWidth: polygonStrokeWidth,
            strokeDashArray: polygonStrokeDashArray,
            fill: polygonFill,
            gradientColor: polygonGradientColor,
            showGradient: polygonShowGradient,
            opacity: polygonOpacity,
            gradientOpacity: polygonGradientOpacity,
            showDataValuesAsLabels: showDataValuesAsLabels
        })
        : null);
    var dataLabelsArray = (_m = props.dataLabelsArray) !== null && _m !== void 0 ? _m : polygonConfigArray === null || polygonConfigArray === void 0 ? void 0 : polygonConfigArray.map(function (polygonItem, index) {
        return polygonItem.showDataValuesAsLabels ? data.map(function (d) { return d.toString(); }) : null;
    });
    var dataLabelsConfigArray = (_p = (_o = props.dataLabelsConfigArray) === null || _o === void 0 ? void 0 : _o.map(function (dataLabelsConfigItem) {
        var _a, _b, _c, _d, _e, _f;
        return ({
            fontSize: (_a = dataLabelsConfigItem.fontSize) !== null && _a !== void 0 ? _a : dataLabelsFontSize,
            stroke: (_b = dataLabelsConfigItem.stroke) !== null && _b !== void 0 ? _b : dataLabelsColor,
            textAnchor: (_c = dataLabelsConfigItem.textAnchor) !== null && _c !== void 0 ? _c : dataLabelsTextAnchor,
            alignmentBaseline: (_d = dataLabelsConfigItem.alignmentBaseline) !== null && _d !== void 0 ? _d : dataLabelsAlignmentBaseline,
            fontWeight: (_e = dataLabelsConfigItem.fontWeight) !== null && _e !== void 0 ? _e : dataLabelsFontWeight,
            fontFamily: (_f = dataLabelsConfigItem.fontFamily) !== null && _f !== void 0 ? _f : dataLabelsFontFamily
        });
    })) !== null && _p !== void 0 ? _p : Array(data.length).fill({
        fontSize: dataLabelsFontSize,
        stroke: dataLabelsColor,
        textAnchor: dataLabelsTextAnchor,
        alignmentBaseline: dataLabelsAlignmentBaseline,
        fontWeight: dataLabelsFontWeight,
        fontFamily: dataLabelsFontFamily
    });
    var dataLabelsConfigSet = (_r = (_q = props.dataLabelsConfigSet) === null || _q === void 0 ? void 0 : _q.map(function (dataLabelConfigSetItem) {
        return dataLabelConfigSetItem.map(function (dataLabelConfigItem) {
            var _a, _b, _c, _d, _e, _f;
            return ({
                fontSize: (_a = dataLabelConfigItem.fontSize) !== null && _a !== void 0 ? _a : dataLabelsFontSize,
                stroke: (_b = dataLabelConfigItem.stroke) !== null && _b !== void 0 ? _b : dataLabelsColor,
                textAnchor: (_c = dataLabelConfigItem.textAnchor) !== null && _c !== void 0 ? _c : dataLabelsTextAnchor,
                alignmentBaseline: (_d = dataLabelConfigItem.alignmentBaseline) !== null && _d !== void 0 ? _d : dataLabelsAlignmentBaseline,
                fontWeight: (_e = dataLabelConfigItem.fontWeight) !== null && _e !== void 0 ? _e : dataLabelsFontWeight,
                fontFamily: (_f = dataLabelConfigItem.fontFamily) !== null && _f !== void 0 ? _f : dataLabelsFontFamily
            });
        });
    })) !== null && _r !== void 0 ? _r : (dataSet ? Array(dataSet.length).fill(dataLabelsConfigArray) : null);
    var _34 = asterLinesConfig.stroke, asterLinesStroke = _34 === void 0 ? gridStroke : _34, _35 = asterLinesConfig.strokeWidth, asterLinesStrokeWidth = _35 === void 0 ? gridStrokeWidth : _35, _36 = asterLinesConfig.strokeDashArray, asterLinesStrokeDashArray = _36 === void 0 ? radarChartDefaults.asterLineStrokeDashArray : _36;
    // Calculate angles for each category
    var angleStep = 360 / labels.length;
    // Generate coordinates for the data points
    var points = data.map(function (value, index) {
        var angle = index * angleStep;
        return polarToCartesian(angle, value);
    });
    var pointsArray = (_s = dataSet === null || dataSet === void 0 ? void 0 : dataSet.map(function (set) {
        return set.map(function (value, index) {
            var angle = index * angleStep;
            return polarToCartesian(angle, value);
        });
    })) !== null && _s !== void 0 ? _s : [];
    // Generate the polygon points for the radar chart (in SVG "x,y" format)
    var polygonPoints = points.map(function (point) { return "".concat(point.x, ",").concat(point.y); }).join(' ');
    var polygonPointsArray = pointsArray.map(function (set) {
        return set.map(function (point) { return "".concat(point.x, ",").concat(point.y); }).join(' ');
    });
    var getGridLevelProps = function (gridItem, ind) {
        var level = noOfSections - ind;
        var gridGradientColorLocal = gridItem.gradientColor || gridGradientColor;
        var gridFillColorLocal = gridItem.fill || gridFill;
        var gridOpacityLocal = gridItem.opacity || gridOpacity;
        var gridGradientOpacityLocal = gridItem.gradientOpacity || gridGradientOpacity;
        var gridStrokeLocal = gridItem.stroke || gridStroke;
        var gridStrokeWidthLocal = gridItem.strokeWidth || gridStrokeWidth;
        var gridShowGradientLocal = gridItem.showGradient || gridShowGradient;
        var gridStrokeDashArrayLocal = gridItem.strokeDashArray || gridStrokeDashArray;
        var levelPoints = labels.map(function (_, index) {
            var angle = index * angleStep;
            return polarToCartesian(angle, (level / noOfSections) * maxValue);
        });
        var levelPolygonPoints = levelPoints
            .map(function (point) { return "".concat(point.x, ",").concat(point.y); })
            .join(' ');
        var r = radius * (level / noOfSections);
        return {
            level: level,
            gridGradientColorLocal: gridGradientColorLocal,
            gridFillColorLocal: gridFillColorLocal,
            gridOpacityLocal: gridOpacityLocal,
            gridGradientOpacityLocal: gridGradientOpacityLocal,
            gridStrokeLocal: gridStrokeLocal,
            gridStrokeWidthLocal: gridStrokeWidthLocal,
            gridShowGradientLocal: gridShowGradientLocal,
            gridStrokeDashArrayLocal: gridStrokeDashArrayLocal,
            levelPoints: levelPoints,
            levelPolygonPoints: levelPolygonPoints,
            r: r
        };
    };
    return {
        data: data,
        dataSet: dataSet,
        center: center,
        radius: radius,
        chartSize: chartSize,
        noOfSections: noOfSections,
        polarToCartesian: polarToCartesian,
        labels: labels,
        labelConfigArray: labelConfigArray,
        labelsPositionOffset: labelsPositionOffset,
        dataLabelsConfigArray: dataLabelsConfigArray,
        maxValue: maxValue,
        dataLabels: dataLabels,
        dataLabelsArray: dataLabelsArray,
        dataLabelsConfigSet: dataLabelsConfigSet,
        gridSections: gridSections,
        gridStroke: gridStroke,
        gridStrokeWidth: gridStrokeWidth,
        gridStrokeDashArray: gridStrokeDashArray,
        gridFill: gridFill,
        gridGradientColor: gridGradientColor,
        gridShowGradient: gridShowGradient,
        gridOpacity: gridOpacity,
        gridGradientOpacity: gridGradientOpacity,
        fontSize: fontSize,
        fontWeight: fontWeight,
        fontFamily: fontFamily,
        stroke: stroke,
        textAnchor: textAnchor,
        alignmentBaseline: alignmentBaseline,
        dataLabelsFontSize: dataLabelsFontSize,
        dataLabelsColor: dataLabelsColor,
        dataLabelsTextAnchor: dataLabelsTextAnchor,
        dataLabelsAlignmentBaseline: dataLabelsAlignmentBaseline,
        dataLabelsPositionOffset: dataLabelsPositionOffset,
        dataLabelsFontWeight: dataLabelsFontWeight,
        dataLabelsFontFamily: dataLabelsFontFamily,
        polygonStroke: polygonStroke,
        polygonStrokeWidth: polygonStrokeWidth,
        polygonStrokeDashArray: polygonStrokeDashArray,
        polygonFill: polygonFill,
        polygonGradientColor: polygonGradientColor,
        polygonShowGradient: polygonShowGradient,
        polygonOpacity: polygonOpacity,
        polygonGradientOpacity: polygonGradientOpacity,
        polygonConfigArray: polygonConfigArray,
        asterLinesStroke: asterLinesStroke,
        asterLinesStrokeWidth: asterLinesStrokeWidth,
        asterLinesStrokeDashArray: asterLinesStrokeDashArray,
        points: points,
        polygonPoints: polygonPoints,
        polygonPointsArray: polygonPointsArray,
        angleStep: angleStep,
        circular: circular,
        hideGrid: hideGrid,
        hideAsterLines: hideAsterLines,
        getGridLevelProps: getGridLevelProps
    };
};
