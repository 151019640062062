import React, { useEffect, useState } from 'react';
import { View, Text, Image, ActivityIndicator, StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import {jwtDecode} from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Feather from '@expo/vector-icons/Feather';
import FontAwesome6 from '@expo/vector-icons/FontAwesome6';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

import AuthContext from './auth/context';
import Landing from './screens/Landing';
import Dashboard from './screens/Dashboard';
import Profile from './screens/Profile';
import CreatePost from './components/CreatePost';
import colors from './config/colors';
import FontLoader from './components/FontLoader';
import Cashflow from './screens/Cashflow';
import Equb from './screens/Equb';
import UserMgmt from './screens/UserMgmt';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const PostStack = () => (
  <Stack.Navigator>
    <Stack.Screen name="Profile" component={Profile} options={{ headerTitle: "", headerShown: false }} />
    <Stack.Screen name="New" component={CreatePost} />
  </Stack.Navigator>
);

const CustomDrawerContent = (props) => {
  return (
    <DrawerContentScrollView {...props}>
      {/* Header Section */}
      <View style={styles.drawerHeader}>
        <Image source={require('./assets/favicon.png')} style={styles.logo} />
        <Text style={styles.companyName}>Equb Debter</Text>
      </View>

      {/* Divider */}
      <View style={styles.divider} />

      {/* Drawer Items */}
      <DrawerItemList {...props} />

      <View style={styles.spacer} />
      
      {/* Additional Custom Items */}
      <View style={styles.bottomSection}>

        <DrawerItem
            label="Logout"
            labelStyle={{fontSize: 16, color:colors.PrimaryBck}}
            icon={({ color, size }) => <MaterialIcons name="logout" color={colors.PrimaryBck} size={size} />}
            onPress={props.logout}
            borderRadius={7}
            style={styles.drawerItemCustom}
        />
        </View>
    </DrawerContentScrollView>
  );
};

export default function App() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  const startExpirationTimer = (expirationTime) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const newInterval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime >= expirationTime) {
        clearInterval(newInterval);
        setIntervalId(null);
        logout();
      }
    }, 15 * 1000);
    setIntervalId(newInterval);
  };

useEffect(() => {
    const loadData = async () => {
      try {
        const [userString, tokenString] = await Promise.all([
          AsyncStorage.getItem('user'),
          AsyncStorage.getItem('token'),
        ]);

        if (userString && tokenString) {
          const decodedToken = jwtDecode(tokenString);
          const expirationTime = decodedToken.exp * 1000;

          if (expirationTime > Date.now()) {
            setUser(JSON.parse(userString));
            setToken(tokenString);
            startExpirationTimer(expirationTime);
          } else {
            logout();
          }
        }
      } catch (error) {
        console.error('Error loading data:', error);
        logout();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const logout = async () => {
    try {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      await AsyncStorage.removeItem('user');
      await AsyncStorage.removeItem('token');
      setUser(null);
      setToken(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#007bff" />
      </View>
    );
  }

  const MainScreen = () => {
    return (
      <NavigationContainer>
        <Drawer.Navigator
          initialRouteName="Home"
          drawerContent={(props) => <CustomDrawerContent {...props} logout={logout} />}
          screenOptions={{
            drawerType:'permanent',
            headerShown: false,
            drawerStyle: {
              backgroundColor: '#f4f4f4',
              width: 200,
            },
            headerStyle: {
              backgroundColor: '#007bff',
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
              fontWeight: 'bold',
              fontSize: 20,
            },
            drawerActiveTintColor: colors.SecondaryBck,
            drawerInactiveTintColor: colors.PrimaryBck,
            drawerLabelStyle: {
              fontSize: 16,
            //   fontWeight: 'bold',
            },
            drawerItemStyle: {
              marginVertical: 4,
              borderRadius: 7,
            },
          }}
        >
          <Drawer.Screen
            name="Home"
            component={Dashboard}
            options={{
              drawerIcon: ({ color, size }) => (
                <MaterialIcons name="home-filled" color={color} size={size} />
              ),
            }}
          />
          <Drawer.Screen
            name="Equb"
            // component={Equb}
            component={Dashboard}
            options={{
              drawerIcon: ({ color, size }) => (
                <MaterialIcons name="savings" size={size} color={color} />
              ),
            }}
          />
          <Drawer.Screen
            name="Cash flow"
            // component={Cashflow}
            component={Dashboard}
            options={{
              drawerIcon: ({ color, size }) => (
                <FontAwesome6 name="money-bill-transfer" size={size-4} color={color} />
              ),
            }}
          />
          <Drawer.Screen
            name="Users"
            // component={UserMgmt}
            component={Dashboard}
            options={{
              drawerIcon: ({ color, size }) => (
                <MaterialIcons name="supervised-user-circle" color={color} size={size} />
              ),
            }}
          />
          {/* <Drawer.Screen
            name="Post"
            component={PostStack}
            options={{
              drawerIcon: ({ color, size }) => (
                <MaterialIcons name="plus" color={color} size={size} />
              ),
            }}
          /> */}
        </Drawer.Navigator>
      </NavigationContainer>
    );
  };

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, logout, startExpirationTimer }}>
      {user && token ? <FontLoader><MainScreen /></FontLoader> :<FontLoader> <Landing /></FontLoader>}
    </AuthContext.Provider>
  );
}

const styles = StyleSheet.create({
  drawerHeader: {
    paddingVertical: 7,
    alignItems:'center',
    flexDirection:'row',
    justifyContent:'center',
    // backgroundColor: '#007bff',
  },
  logo: {
    width: 30,
    height: 30,
    borderRadius: 7,
    // marginBottom: 10,
    marginRight:5,
  },
  companyName: {
    fontSize: 21,
    fontWeight: 'bold',
    color: colors.PrimaryBck,
  },
  divider: {
    height: 1,
    backgroundColor: '#ccc',
    marginVertical: 10,
  },
  spacer:{
    height:450
  },
   bottomSection: {
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    // paddingVertical: 100,
    // flex:1,
    height:'100%',
    // backgroundColor:'orange',
  },
  drawerItemCustom:{
    // backgroundColor:'orange',
    borderRadius:7,
    // flex:1
    paddingTop:3,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

// import React, { useEffect, useState } from 'react';
// import {View, Text, ActivityIndicator} from 'react-native'
// import { NavigationContainer } from '@react-navigation/native';
// import { createDrawerNavigator } from '@react-navigation/drawer';
// import { createStackNavigator } from '@react-navigation/stack';

// import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
// import Feather from '@expo/vector-icons/Feather';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import {jwtDecode} from 'jwt-decode'

// import AuthContext from './auth/context';
// import Landing from './screens/Landing';
// import FontLoader from './components/FontLoader';
// import Dashbaord from './screens/Dashboard';
// import Profile from './screens/Profile';
// import CreatePost from './components/CreatePost';
// import colors from './config/colors';


// const Drawer = createDrawerNavigator();
// const Stack = createStackNavigator();

// const PostStack = () => (
//   <Stack.Navigator>
//     <Stack.Screen name="Profile" component={Profile} options={{ headerTitle: "", headerShown: false }} />
//     <Stack.Screen name="New" component={CreatePost} />
//   </Stack.Navigator>
// );

// export default function App() {
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [intervalId, setIntervalId] = useState(null);
//   const [isDrawerPermanent, setIsDrawerPermanent] = useState(true); // Control the drawer type

//   const startExpirationTimer = (expirationTime) => {
//     if (intervalId) {
//       clearInterval(intervalId);
//     }
//     const newInterval = setInterval(() => {
//       const currentTime = Date.now();
//       if (currentTime >= expirationTime) {
//         clearInterval(newInterval);
//         setIntervalId(null);
//         logout();
//       }
//     }, 15 * 1000);
//     setIntervalId(newInterval);
//   };

//   useEffect(() => {
//     const loadData = async () => {
//       try {
//         const [userString, tokenString] = await Promise.all([
//           AsyncStorage.getItem('user'),
//           AsyncStorage.getItem('token'),
//         ]);

//         if (userString && tokenString) {
//           const decodedToken = jwtDecode(tokenString);
//           const expirationTime = decodedToken.exp * 1000;

//           if (expirationTime > Date.now()) {
//             setUser(JSON.parse(userString));
//             setToken(tokenString);
//             startExpirationTimer(expirationTime);
//           } else {
//             logout();
//           }
//         }
//       } catch (error) {
//         console.error('Error loading data:', error);
//         logout();
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     loadData();

//     return () => {
//       if (intervalId) {
//         clearInterval(intervalId);
//       }
//     };
//   }, []);

//   const logout = async () => {
//     try {
//       if (intervalId) {
//         clearInterval(intervalId);
//         setIntervalId(null);
//       }
//       await AsyncStorage.removeItem('user');
//       await AsyncStorage.removeItem('token');
//       setUser(null);
//       setToken(null);
//     } catch (error) {
//       console.error('Logout error:', error);
//     }
//   };

//   if (isLoading) {
//     return (
//       <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
//         <ActivityIndicator size="large" color="#007bff" />
//       </View>
//     );
//   }
//   const CustomDrawerContent = (props) => {
//     return (
//       <View style={{ flex: 1 }}>
//         {/* Logo and Company Name */}
//         <View style={{ alignItems: 'center', paddingVertical: 20, backgroundColor: '#007bff' }}>
//           <Text style={{ fontSize: 24, color: '#fff', fontWeight: 'bold' }}>Company Name</Text>
//         </View>
//         {/* Drawer Items */}
//         <View style={{ flex: 1 }}>
//           {/* <DrawerItemList {...props} /> */}
//         </View>
//       </View>
//     );
//   };
//   const MainScreen = () => {
//     return (
//       <NavigationContainer>
//         <Drawer.Navigator
//         drawerContent={(props)=> <CustomDrawerContent {...props}/>}
//           initialRouteName="Dashboard"
//           screenOptions={{
//             headerShown: true,
//             drawerType: isDrawerPermanent ? 'permanent' : 'front', // Dynamic drawer type
//             drawerStyle: {
//               backgroundColor: '#f4f4f4',
//               width: 200,
//             },
//             headerStyle: {
//               backgroundColor: '#007bff',
//             },
//             headerTintColor: '#fff',
//             headerTitleStyle: {
//               fontWeight: 'bold',
//               fontSize: 20,
//             },
//             drawerActiveTintColor: '#007bff',
//             drawerInactiveTintColor: '#333',
//             drawerLabelStyle: {
//               fontSize: 16,
//               fontWeight: 'bold',
//             },
//             drawerItemStyle: {
//               marginVertical: 5,
//               borderRadius: 8,
//             },
//           }}
//         >
//           <Drawer.Screen
//             name="Dashboard"
//             component={Dashbaord}
//             options={{
//               drawerIcon: ({ color, size }) => (
//                 <MaterialCommunityIcons name="home" color={color} size={size} />
//               ),
//               drawerLabel: 'Dashboard',
//               headerRight: () => (
//                 <MaterialCommunityIcons
//                 //   name={isDrawerPermanent ? 'lock' : 'lock-open'}
//                   name={isDrawerPermanent ? 'logout' : 'logout'}
//                   size={24}
//                   color="#fff"
//                 //   onPress={() => setIsDrawerPermanent((prev) => !prev)} // Toggle drawer type
//                   onPress={() => logout()} // Toggle drawer type
//                   style={{ marginRight: 15 }}
//                 />
//               ),
//             }}
//           />
//           <Drawer.Screen
//             name="Post"
//             component={PostStack}
//             options={{
//               drawerIcon: ({ color, size }) => (
//                 <MaterialCommunityIcons name="plus" color={color} size={size} />
//               ),
//               drawerLabel: '',
//             }}
//           />

//           <Drawer.Screen
//             name="Activity"
//             component={PostStack}
//             options={{
//               drawerIcon: ({ color, size }) => (
//                 <Feather name="activity" color={color} size={size} />
//               ),
//               drawerLabel: '',
//             }}
//           />
//         </Drawer.Navigator>
//       </NavigationContainer>
//     );
//   };

//   return (
//     <AuthContext.Provider value={{ user, setUser, token, setToken, logout, startExpirationTimer }}>
//       {user && token ? <MainScreen /> : <Landing />}
//     </AuthContext.Provider>
//   );
// }
