import client from './client'

const getUserActivity = (coTin) => client.get(`/getUserActivity`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getEmpActivity = (coTin) => client.get(`/getEmpActivity`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getDepositMediumSummary = (coTin) => client.get(`/getDepositMediumSummary`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getWithdrawalMediumSummary = (coTin) => client.get(`/getWithdrawalMediumSummary`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getDepositPerMedium = (coTin) => client.get(`/getDepositPerMedium`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getWithdrawalPerMedium = (coTin) => client.get(`/getWithdrawalPerMedium`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getTotalDeposit = (coTin) => client.get(`/getTotalDeposit`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getTotalWithdrawal = (coTin) => client.get(`/getTotalWithdrawal`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getActiveEqub = (coTin) => client.get(`/getActiveEqub`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getActiveCollectors = (coTin) => client.get(`/getActiveCollectors`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

const getActiveSavers = (coTin) => client.get(`/getActiveSavers`, {params :{coTin}}, {'Access-Control-Allow-Origin': '*'})

export default{
    getUserActivity,
    getEmpActivity,
    getDepositMediumSummary,
    getWithdrawalMediumSummary,
    getDepositPerMedium,
    getWithdrawalPerMedium,
    getTotalDeposit,
    getTotalWithdrawal,
    getActiveEqub,
    getActiveCollectors,
    getActiveSavers
}