var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { View, TouchableOpacity, Animated, Text } from 'react-native';
import AnimatedThreeDBar from '../Components/AnimatedThreeDBar';
import Animated2DWithGradient from './Animated2DWithGradient';
import { getPropsForAnimated2DWithGradient, useRenderBars, } from 'gifted-charts-core';
import Tooltip from '../Components/BarSpecificComponents/tooltip';
var RenderBars = function (props) {
    var _a, _b;
    var item = props.item, index = props.index, containerHeight = props.containerHeight, maxValue = props.maxValue, minHeight = props.minHeight, spacing = props.spacing, side = props.side, data = props.data, barBorderWidth = props.barBorderWidth, barBorderColor = props.barBorderColor, isThreeD = props.isThreeD, isAnimated = props.isAnimated, rotateLabel = props.rotateLabel, appearingOpacity = props.appearingOpacity, animationDuration = props.animationDuration, autoShiftLabels = props.autoShiftLabels, label = props.label, secondaryLabel = props.secondaryLabel, labelTextStyle = props.labelTextStyle, secondaryLabelTextStyle = props.secondaryLabelTextStyle, xAxisTextNumberOfLines = props.xAxisTextNumberOfLines, xAxisLabelsVerticalShift = props.xAxisLabelsVerticalShift, renderTooltip = props.renderTooltip, selectedIndex = props.selectedIndex, setSelectedIndex = props.setSelectedIndex, horizontal = props.horizontal, rtl = props.rtl, pointerConfig = props.pointerConfig, noOfSectionsBelowXAxis = props.noOfSectionsBelowXAxis, barWidth = props.barWidth, _c = props.labelsDistanceFromXaxis, labelsDistanceFromXaxis = _c === void 0 ? 0 : _c, secondaryXAxis = props.secondaryXAxis, secondaryNoOfSectionsBelowXAxis = props.secondaryNoOfSectionsBelowXAxis, _d = props.barMarginBottom, barMarginBottom = _d === void 0 ? 0 : _d;
    var _e = useRenderBars(props), heightFactor = _e.heightFactor, barHeight = _e.barHeight, tooltipProps = _e.tooltipProps;
    var _f = getPropsForAnimated2DWithGradient(__assign(__assign({}, props), { barHeight: barHeight })), commonStyleForBar = _f.commonStyleForBar, barStyleWithBackground = _f.barStyleWithBackground, commonPropsFor2dAnd3dBars = _f.commonPropsFor2dAnd3dBars, isFocused = _f.isFocused, focusedBarConfig = _f.focusedBarConfig, localFrontColor = _f.localFrontColor;
    var itemOrPropsBarInnerComponent = (_a = item.barInnerComponent) !== null && _a !== void 0 ? _a : props.barInnerComponent;
    var localBarInnerComponent = isFocused
        ? ((_b = focusedBarConfig === null || focusedBarConfig === void 0 ? void 0 : focusedBarConfig.barInnerComponent) !== null && _b !== void 0 ? _b : itemOrPropsBarInnerComponent)
        : itemOrPropsBarInnerComponent;
    var renderLabel = function (top, label, labelTextStyle, value) {
        var _a, _b;
        return (_jsx(View, { style: [
                {
                    width: (item.labelWidth ||
                        props.labelWidth ||
                        item.barWidth ||
                        barWidth) + spacing,
                    left: spacing / -2,
                    position: 'absolute',
                    height: (_a = props.xAxisLabelsHeight) !== null && _a !== void 0 ? _a : xAxisTextNumberOfLines * 18,
                    bottom: top
                        ? (containerHeight || 200) +
                            ((_b = secondaryXAxis === null || secondaryXAxis === void 0 ? void 0 : secondaryXAxis.labelsDistanceFromXaxis) !== null && _b !== void 0 ? _b : 15)
                        : (rotateLabel
                            ? -40
                            : -6 -
                                xAxisTextNumberOfLines * 18 -
                                (value < 0
                                    ? -xAxisLabelsVerticalShift
                                    : xAxisLabelsVerticalShift)) -
                            barMarginBottom -
                            (value < 0 && !autoShiftLabels
                                ? -labelsDistanceFromXaxis
                                : labelsDistanceFromXaxis),
                },
                rotateLabel
                    ? horizontal
                        ? { transform: [{ rotate: '330deg' }] }
                        : {
                            transform: [
                                { rotate: value < 0 ? '240deg' : '60deg' },
                                { translateX: value < 0 ? 56 : 0 },
                                { translateY: value < 0 ? 32 : 0 },
                            ],
                        }
                    : horizontal
                        ? { transform: [{ rotate: '-90deg' }] }
                        : value < 0
                            ? {
                                transform: [
                                    { rotate: '180deg' },
                                    {
                                        translateY: autoShiftLabels
                                            ? 0
                                            : 16.5 * xAxisTextNumberOfLines + 12,
                                    },
                                ],
                            }
                            : {},
            ], children: top ? (item.secondaryLabelComponent ? (item.secondaryLabelComponent()) : (_jsx(Text, { style: [
                    { textAlign: 'center' },
                    rtl && horizontal && { transform: [{ rotate: '180deg' }] },
                    labelTextStyle,
                ], numberOfLines: xAxisTextNumberOfLines, children: label }))) : item.labelComponent ? (item.labelComponent()) : (_jsx(Text, { style: [
                    { textAlign: 'center' },
                    rtl && horizontal && { transform: [{ rotate: '180deg' }] },
                    labelTextStyle,
                ], numberOfLines: xAxisTextNumberOfLines, children: label })) }));
    };
    var renderAnimatedLabel = function (top, label, labelTextStyle, value) {
        var _a, _b;
        return (_jsx(Animated.View, { style: [
                {
                    width: (item.labelWidth ||
                        props.labelWidth ||
                        item.barWidth ||
                        barWidth) + spacing,
                    left: spacing / -2,
                    position: 'absolute',
                    height: (_a = props.xAxisLabelsHeight) !== null && _a !== void 0 ? _a : xAxisTextNumberOfLines * 18 -
                        (value < 0
                            ? -xAxisLabelsVerticalShift
                            : xAxisLabelsVerticalShift),
                    bottom: top
                        ? (containerHeight || 200) +
                            ((_b = secondaryXAxis === null || secondaryXAxis === void 0 ? void 0 : secondaryXAxis.labelsDistanceFromXaxis) !== null && _b !== void 0 ? _b : 15)
                        : (rotateLabel
                            ? -40
                            : -6 -
                                xAxisTextNumberOfLines * 18 -
                                xAxisLabelsVerticalShift) - barMarginBottom,
                    opacity: appearingOpacity,
                },
                value < 0 && { transform: [{ rotate: '180deg' }] },
                rotateLabel
                    ? horizontal
                        ? { transform: [{ rotate: '330deg' }] }
                        : { transform: [{ rotate: '60deg' }] }
                    : horizontal
                        ? { transform: [{ rotate: '-90deg' }] }
                        : value < 0
                            ? {
                                transform: [
                                    { rotate: '180deg' },
                                    {
                                        translateY: autoShiftLabels
                                            ? 0
                                            : 16.5 * xAxisTextNumberOfLines + 12,
                                    },
                                ],
                            }
                            : {},
            ], children: top ? (item.secondaryLabelComponent ? (item.secondaryLabelComponent()) : (_jsx(Text, { style: [
                    { textAlign: 'center' },
                    rtl && horizontal && { transform: [{ rotate: '180deg' }] },
                    labelTextStyle,
                ], numberOfLines: xAxisTextNumberOfLines, children: label }))) : item.labelComponent ? (item.labelComponent()) : (_jsx(Text, { style: [
                    { textAlign: 'center' },
                    rtl && horizontal && { transform: [{ rotate: '180deg' }] },
                    labelTextStyle,
                ], numberOfLines: xAxisTextNumberOfLines, children: label })) }));
    };
    var barWrapperStyle = [
        {
            // overflow: 'visible',
            marginBottom: 60 + barMarginBottom + xAxisLabelsVerticalShift - 0.5,
            width: commonPropsFor2dAnd3dBars.barWidth,
            height: barHeight,
            marginRight: spacing,
            transform: [
                {
                    translateY: (containerHeight || 200) -
                        (barHeight - 10 + xAxisLabelsVerticalShift) +
                        (item.value < 0 ? Math.abs(item.value) * heightFactor : 0),
                },
                { rotateZ: item.value < 0 ? '180deg' : '0deg' },
            ],
        },
        side !== 'right' && { zIndex: data.length - index },
    ];
    var pressDisabled = item.disablePress ||
        props.disablePress ||
        (pointerConfig && pointerConfig.barTouchable !== true);
    var barContent = function () {
        var isBarBelowXaxisAndInvisible = item.value < 0 &&
            !(noOfSectionsBelowXAxis || secondaryNoOfSectionsBelowXAxis);
        var animated2DWithGradient = function (noGradient, noAnimation) { return (_jsx(Animated2DWithGradient, __assign({}, commonPropsFor2dAnd3dBars, { animationDuration: animationDuration || 800, roundedBottom: props.roundedBottom || false, roundedTop: props.roundedTop || false, noGradient: noGradient, noAnimation: noAnimation, containerHeight: containerHeight, maxValue: maxValue, minHeight: minHeight !== null && minHeight !== void 0 ? minHeight : 0, barMarginBottom: barMarginBottom, cappedBars: props.cappedBars, capThickness: props.capThickness, capColor: props.capColor, capRadius: props.capRadius, horizontal: horizontal, barBorderWidth: barBorderWidth, barBorderColor: barBorderColor, commonStyleForBar: commonStyleForBar, barStyleWithBackground: barStyleWithBackground }))); };
        return (_jsxs(_Fragment, { children: [(props.showXAxisIndices || item.showXAxisIndex) && (_jsx(View, { style: {
                        zIndex: 2,
                        position: 'absolute',
                        height: props.xAxisIndicesHeight,
                        width: props.xAxisIndicesWidth,
                        bottom: props.xAxisIndicesHeight / -2,
                        left: ((item.barWidth || barWidth) - props.xAxisIndicesWidth) / 2,
                        backgroundColor: props.xAxisIndicesColor,
                    } })), isBarBelowXaxisAndInvisible ? null : isThreeD ? (_jsx(AnimatedThreeDBar, __assign({}, commonPropsFor2dAnd3dBars, { sideWidth: item.sideWidth ||
                        props.sideWidth ||
                        (item.barWidth || barWidth) / 2, side: side || 'left', sideColor: item.sideColor || props.sideColor || '', topColor: item.topColor || props.topColor || '', horizontal: horizontal, isAnimated: isAnimated, animationDuration: animationDuration || 800, selectedIndex: selectedIndex }))) : item.showGradient || props.showGradient ? (isAnimated ? (animated2DWithGradient(false, false)) : (animated2DWithGradient(false, true))) : isAnimated ? (animated2DWithGradient(true, false)) : (animated2DWithGradient(true, true)), isAnimated
                    ? renderAnimatedLabel(false, label, labelTextStyle, item.value)
                    : renderLabel(false, label, labelTextStyle, item.value), secondaryXAxis
                    ? isAnimated
                        ? renderAnimatedLabel(true, secondaryLabel, secondaryLabelTextStyle, item.value)
                        : renderLabel(true, secondaryLabel, secondaryLabelTextStyle, item.value)
                    : null] }));
    };
    return (_jsxs(_Fragment, { children: [pressDisabled ? (_jsx(View, { pointerEvents: "none", style: barWrapperStyle, children: barContent() })) : (_jsx(TouchableOpacity, { activeOpacity: props.activeOpacity || 0.2, onPress: function () {
                    if (renderTooltip || props.focusBarOnPress) {
                        if (props.focusedBarIndex === undefined || !props.onPress) {
                            setSelectedIndex(index);
                        }
                    }
                    item.onPress
                        ? item.onPress()
                        : props.onPress
                            ? props.onPress(item, index)
                            : null;
                }, onLongPress: function () {
                    item.onLongPress
                        ? item.onLongPress()
                        : props.onLongPress
                            ? props.onLongPress(item, index)
                            : null;
                }, onPressOut: function () {
                    item.onPressOut
                        ? item.onPressOut()
                        : props.onPressOut
                            ? props.onPressOut(item, index)
                            : null;
                }, style: barWrapperStyle, children: barContent() })), renderTooltip && selectedIndex === index && (_jsx(Tooltip, __assign({}, tooltipProps)))] }));
};
export default RenderBars;
