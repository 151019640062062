
import React from 'react';
import { Text } from 'react-native';

function MoneyFormat({ amount }) {
  if (amount === null || amount === undefined) {
    return null; // Handle null or undefined gracefully
  }

  // Ensure the amount is a number and fix it to 2 decimal places
  const amountStr = Number(amount).toFixed(2);

  // Split the amount into dollars and cents
  const [dollars, cents] = amountStr.split(".");

  // Format the dollars part with commas
  const dollarsWithCommas = addCommas(dollars);

  // Combine dollars and cents
  const formattedAmount = `${dollarsWithCommas}.${cents}`;

  return <Text>{formattedAmount}</Text>;
}

function addCommas(number) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default MoneyFormat;
// import React from 'react';
// import { Text } from 'react-native';

// function MoneyFormat({ amount }) {
//     if (amount === null) {
//         return null
//     }
//     // Convert the amount to a string with 2 decimal places
//     const amountStr = amount.toFixed(2);

//     // Split the amount into dollars and cents
//     const [dollars, cents] = amountStr.split(".");

//     // Add commas to the dollars part
//     const dollarsWithCommas = addCommas(dollars);

//     // Combine the dollars and cents parts
//     let formattedAmount = dollarsWithCommas;
//     if (cents === "00") {
//         formattedAmount += ".00";
//     } else {
//         formattedAmount += `.${cents}`;
//     }

//     return (
//         <Text>{formattedAmount}</Text>
//     );
// }

// function addCommas(number) {
//     return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

// export default MoneyFormat;